import React, { useState, useRef } from 'react'
import './index.css'
import '../SharedCss/alert.css'
import '../SharedCss/form.css'
import { signInExternalHost } from '../ExternalHosts'
import { signIn } from '../Auth'
import Footer from '../SharedComponents/Footer'
import LoadingBar from 'react-top-loading-bar'
import ALERT_TYPES from '../SharedConstants/AlertTypes'
require('dotenv').config()

export default function NewPasswordForm (props) {
  const { cognitoUser, sessionUserAttributes } = props
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [confirmation, setConfirmation] = useState('')
  const [alertType, setAlertType] = useState('')
  const loadingRef = useRef(null)

  const handleSubmit = (e) => {
    e.preventDefault()

    if (password !== confirmation) {
      setAlertType(ALERT_TYPES.UPDATE_FAILED)
      setErrorMessage('Password and password confirmation do not match')
      return false
    }

    if (password.trim().length === 0) {
      setAlertType(ALERT_TYPES.UPDATE_FAILED)
      setErrorMessage('Please enter your password')
      return false
    }

    loadingRef.current.continuousStart()

    cognitoUser.completeNewPasswordChallenge(password, sessionUserAttributes, {
      onSuccess: (tokens) => {
        signIn(tokens)
        signInExternalHost().then(() => {
          loadingRef.current.complete()
        })
      },
      onFailure: (err) => {
        loadingRef.current.complete()
        setAlertType(ALERT_TYPES.UPDATE_FAILED)
        setErrorMessage(err.message)
      }
    })
  }

  const renderAlert = () => {
    if (alertType === ALERT_TYPES.UPDATE_FAILED) {
      return (
        <div styleName="alert error">
          { errorMessage }
        </div>
      )
    }
  }

  return <div styleName="container">
    { renderAlert() }
    <LoadingBar color='#f11946' ref={loadingRef} />

    <div styleName="header">
      <div styleName="logo">
        <h1>Blueleaf</h1>
      </div>

      <div styleName="header-text">
        <h2>Set your new password</h2>
      </div>
    </div>

    <div styleName="form-container">
      <form id="new-password-form" onSubmit={handleSubmit}>
        <div styleName="fieldset">
          <label styleName="label">
            Password
          </label>
          <input styleName="input-field" id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>

        <div styleName="fieldset">
          <label styleName="label">
            Confirm your new password
          </label>
          <input styleName="input-field" type="password" value={confirmation} onChange={(e) => setConfirmation(e.target.value)} />
        </div>

        <input id="submit-btn" styleName="submit-btn" type="submit" value="Set" />
        <a id="sign-in-btn" styleName="link" href="/" >Back to sign in</a>
      </form>
    </div>

    <Footer />
  </div>
}
