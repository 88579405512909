import React, { useState, useRef } from 'react'
import './index.css'
import '../SharedCss/alert.css'
import '../SharedCss/form.css'
import { signInExternalHost, signInExternalHostWithCustomRedirect } from '../ExternalHosts'
import { signIn } from '../Auth'
import ALERT_TYPES from '../SharedConstants/AlertTypes'
import Footer from '../SharedComponents/Footer'
import Logger from '../Logger'
import LoadingBar from 'react-top-loading-bar'
require('dotenv').config()

export default function MfaForm (props) {
  const { cognitoUser, destination } = props
  const [code, setCode] = useState('')
  const [alertType, setAlertType] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const loadingRef = useRef(null)
  const params = new URLSearchParams(window.location.search)
  const returnToUrl = params.get('return_to')

  const handleSubmit = (e) => {
    Logger.start('MFA submit')
    loadingRef.current.continuousStart()
    e.preventDefault()

    cognitoUser.sendMFACode(code, {
      onSuccess: (tokens) => {
        Logger.done('MFA submit')
        signIn(tokens)
        if (returnToUrl) {
          signInExternalHostWithCustomRedirect(returnToUrl)
        } else {
          signInExternalHost()
        }
      },
      onFailure: (err) => {
        loadingRef.current.complete()
        Logger.error('MFA submit', err)
        setAlertType(ALERT_TYPES.UPDATE_FAILED)
        setErrorMessage(err.message || JSON.stringify(err))
      }
    })
  }

  const renderAlert = () => {
    if (alertType === ALERT_TYPES.UPDATE_FAILED) {
      return (
        <div styleName="alert error">
          {errorMessage}
        </div>
      )
    }
  }

  return <div styleName="container">
    { renderAlert() }
    <LoadingBar color='#f11946' ref={loadingRef} />

    <div styleName="header">
      <div styleName="logo">
        <h1>Blueleaf</h1>
      </div>

      <div styleName="header-text">
        <h2>Verify your identity</h2>
      </div>
    </div>

    <div styleName="form-container">
      <form id="forgot-password-form" onSubmit={handleSubmit}>
        <div styleName="form-description">
          We&rsquo;ve sent a temporary access code to your phone number ending {destination}
        </div>
        <div styleName="fieldset">
          <label styleName="label">
            Enter code
          </label>
          <input styleName="input-field" type="text" value={code} onChange={(e) => setCode(e.target.value)} />
        </div>

        <input id="submit-btn" styleName="submit-btn" type="submit" value="Verify" />
        <a id="sign-in-btn" styleName="link" href="/" >Didn&rsquo;t receive your code? Try login again.</a>
      </form>
    </div>

    <Footer />
  </div>
}
